// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-js": () => import("/opt/build/repo/src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-post-js": () => import("/opt/build/repo/src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-tag-js": () => import("/opt/build/repo/src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "component---src-templates-index-js": () => import("/opt/build/repo/src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-page-js": () => import("/opt/build/repo/src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-pages-404-en-us-js": () => import("/opt/build/repo/src/pages/404.en-US.js" /* webpackChunkName: "component---src-pages-404-en-us-js" */),
  "component---src-pages-404-fr-js": () => import("/opt/build/repo/src/pages/404.fr.js" /* webpackChunkName: "component---src-pages-404-fr-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-optiflow-en-us-js": () => import("/opt/build/repo/src/pages/optiflow.en-US.js" /* webpackChunkName: "component---src-pages-optiflow-en-us-js" */),
  "component---src-pages-optiflow-fr-js": () => import("/opt/build/repo/src/pages/optiflow.fr.js" /* webpackChunkName: "component---src-pages-optiflow-fr-js" */),
  "component---src-pages-optismed-en-us-js": () => import("/opt/build/repo/src/pages/optismed.en-US.js" /* webpackChunkName: "component---src-pages-optismed-en-us-js" */),
  "component---src-pages-optismed-fr-js": () => import("/opt/build/repo/src/pages/optismed.fr.js" /* webpackChunkName: "component---src-pages-optismed-fr-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

